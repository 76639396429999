.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}
}