.static-view {
	margin-top: var(--page-margin-top);

	&.Neuigkeiten-v1,
	&.ausflugstippsharz-v1,
	&.AusflugstippsOstsee-v1 {
		.img-thumbnail {
			width: 100%;
			border: 0;
			padding: 0;
			border-radius: 0;
		}
	}

	.inner-banner {
		&.contact-banner {
			background-image: url(RESOURCE/img/inner-banner-new.jpeg);
		}

		&.agb {
			background-image: url(RESOURCE/img/inner-banner-new.jpeg);
		}

		&.imp-banner {
			background-image: url(RESOURCE/img/inner-banner-new.jpeg);
		}

		&.AusflugstippsOstsee {
			background-image: url(RESOURCE/img/ausflugstipps_banner_new02.jpg);
		}

		&.neuigkeiten {
			background-image: url(RESOURCE/img/neuigkeiten_newbanner01.jpg);
		}

		&.Pfoten {
			background-image: url(RESOURCE/img/Hundeurlaub01.jpg);
		}

		&.contact-banner {
			background-image: url(RESOURCE/img/kontact-banner.png);
		}
	}
}